import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Title } from "@skyportal/ui-kit";
import { generateCsvExportUrl } from "utils/helpers";

import styles from "./styles.module.css";
import ModuleBreadcrumbs from "components/ModuleBreadcrumbs";

export interface PageHeaderProps {
  title: React.ReactNode;
}

const PageHeader: React.FC<PageHeaderProps> = ({ title }) => {
  const { t } = useTranslation("customerStatusTable");

  return (
    <header className={styles.header}>
      <ModuleBreadcrumbs />
      <div className={styles.headerContent}>
        <Title level={2}>{title}</Title>
        <a href={generateCsvExportUrl("customer-status")} download>
          <Button data-testId="exportAsCsvBtn">{t("customerStatusPage:exportAsCsvBtn")}</Button>
        </a>
      </div>
    </header>
  );
};

export default PageHeader;
