import React, { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import cn from "classnames";
import { customerStatusActions, customerStatusSelectors } from "store/customerStatus";
import { CustomerStatusModel } from "types/customerStatusApi.types";
import { useTranslation } from "react-i18next";
import { Select, TextInput } from "@skyportal/ui-kit";
import { isNumber } from "utils/helpers";
import {
  getAdminsWithMFAStatus,
  getBitlockerActivatedStatus,
  getGlobalAdminsStatus,
  getNotSignedInFor6MonthsStatus,
  getSecureScoreStatus,
  getSignedInRiskUsersStatus,
  getTPMOlderThan20Status,
  getUsersWithMFAStatus,
  getWindows11Status,
} from "../components/CustomerStatusTable/helpers";
import { dummyCustomerStatus } from "components/CustomerStatusTable/CustomerStatusTable";

import styles from "../components/CustomerStatusTable/styles.module.css";

export type SelectOptionItem = {
  value: string;
  label: string;
};

export enum CustomerStatus {
  Success = "Success",
  Warning = "Warning",
  Failed = "Failed",
}

export const customerStatusStyles = {
  [CustomerStatus.Success]: styles.successBadge,
  [CustomerStatus.Warning]: styles.warningBadge,
  [CustomerStatus.Failed]: styles.failedBadge,
};

const useCustomerStatusTableConfig = () => {
  const { t } = useTranslation("customerStatusTable");
  const dispatch = useDispatch();
  const { list: initialList, filters } = useSelector(customerStatusSelectors.getSlice);

  const [isCustomerStatusModalOpen, setIsCustomerStatusModalOpen] = useState(false);
  const [selectedCustomerStatusItem, setSelectedCustomerStatusItem] =
    useState<CustomerStatusModel>(dummyCustomerStatus);

  const handleOpenModalBtn = useCallback((customerStatusItem: CustomerStatusModel) => {
    setSelectedCustomerStatusItem(customerStatusItem);
    setIsCustomerStatusModalOpen(true);
  }, []);
  const handleCloseModalBtn = useCallback(() => {
    setIsCustomerStatusModalOpen(false);
  }, []);

  const agreementsOptions: SelectOptionItem[] = useMemo(
    () =>
      (initialList as any[])
        .reduce((acc, item) => {
          item.agreements?.forEach((agreement: string) => !acc.includes(agreement) && acc.push(agreement));
          return acc;
        }, [])
        .map((agreement: string) => ({ value: agreement, label: agreement })),
    [initialList]
  );

  const handleSelectFilterChange = useCallback(
    (value, fieldName) => {
      dispatch(customerStatusActions.setCustomerStatusFiltersAC({ ...filters, [fieldName]: value }));
      dispatch(customerStatusActions.setCustomerStatusListCurrentPageAC(1));
    },
    [dispatch, filters]
  );
  const handleFilterChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, fieldName) => {
      dispatch(customerStatusActions.setCustomerStatusFiltersAC({ ...filters, [fieldName]: event.target.value }));
      dispatch(customerStatusActions.setCustomerStatusListCurrentPageAC(1));
    },
    [dispatch, filters]
  );

  const columnsConfig = useMemo(
    () => [
      {
        key: "customerName",
        dataIndex: "customerName",
        title: t("customerName"),
        width: "150px",
        render: (customerName: CustomerStatusModel["customerName"], customerStatus: CustomerStatusModel) =>
          customerName !== "_" ? (
            <div className={styles.tableCell} onClick={() => handleOpenModalBtn(customerStatus)}>
              {customerName ?? <span>&nbsp;</span>}
            </div>
          ) : (
            <TextInput
              data-testid="tableFilter-customerName"
              className={styles.filterInput}
              value={filters.customerName}
              onChange={(e) => handleFilterChange(e, "customerName")}
            />
          ),
      },
      {
        key: "customerId",
        dataIndex: "customerId",
        title: t("customerId"),
        width: "100px",
        render: (customerId: CustomerStatusModel["customerId"], customerStatus: CustomerStatusModel) =>
          customerId !== "_" ? (
            <div className={styles.tableCell} onClick={() => handleOpenModalBtn(customerStatus)}>
              {customerId ?? <span>&nbsp;</span>}
            </div>
          ) : (
            <TextInput
              data-testid="tableFilter-customerId"
              className={styles.filterInput}
              value={filters.customerId}
              onChange={(e) => handleFilterChange(e, "customerId")}
            />
          ),
      },
      {
        key: "agreementIds",
        dataIndex: "agreementIds",
        title: t("agreementIds"),
        width: "100px",
        render: (agreementIds: CustomerStatusModel["agreementIds"], customerStatus: CustomerStatusModel) =>
          Array.isArray(agreementIds) ? (
            <div className={styles.tableCell} onClick={() => handleOpenModalBtn(customerStatus)}>
              {agreementIds.join(", ") || <span>&nbsp;</span>}
            </div>
          ) : (
            <TextInput
              data-testid="tableFilter-agreementIds"
              className={styles.filterInput}
              value={filters.agreementIds}
              onChange={(e) => handleFilterChange(e, "agreementIds")}
            />
          ),
      },
      {
        key: "agreements",
        dataIndex: "agreements",
        title: t("agreements"),
        width: "200px",
        render: (agreements: CustomerStatusModel["agreements"], customerStatus: CustomerStatusModel) =>
          Array.isArray(agreements) ? (
            <div className={styles.tableCell} onClick={() => handleOpenModalBtn(customerStatus)}>
              {agreements.join(", ") || <span>&nbsp;</span>}
            </div>
          ) : (
            <Select
              data-testid="tableFilter-agreements"
              className={styles.filterSelect}
              options={[{ value: "", label: "" }, ...agreementsOptions]}
              value={filters.agreements}
              onChange={(value) => handleSelectFilterChange(value, "agreements")}
            />
          ),
      },
      {
        key: "tenantIds",
        dataIndex: "tenantIds",
        title: t("tenantIds"),
        width: "200px",
        render: (tenantIds: CustomerStatusModel["tenantIds"], customerStatus: CustomerStatusModel) =>
          tenantIds !== "_" ? (
            <div className={styles.tableCell} onClick={() => handleOpenModalBtn(customerStatus)}>
              {Object.values(tenantIds).join(", ") ?? <span>&nbsp;</span>}
            </div>
          ) : null,
      },
      {
        key: "secureScore",
        dataIndex: "secureScore",
        title: t("secureScore"),
        width: "120px",
        render: (secureScore: CustomerStatusModel["secureScore"], customerStatus: CustomerStatusModel) =>
          secureScore !== "_" ? (
            <div className={styles.tableCell} onClick={() => handleOpenModalBtn(customerStatus)}>
              {isNumber(secureScore) ? (
                <div className={cn(styles.badge, customerStatusStyles[getSecureScoreStatus(secureScore)])}>
                  {secureScore}%
                </div>
              ) : (
                <span>&nbsp;</span>
              )}
            </div>
          ) : null,
      },
      {
        key: "notSignedInFor6Months",
        dataIndex: "notSignedInFor6Months",
        title: t("notSignedInFor6Months"),
        width: "120px",
        render: (
          notSignedInFor6Months: CustomerStatusModel["notSignedInFor6Months"],
          customerStatus: CustomerStatusModel
        ) =>
          notSignedInFor6Months !== "_" ? (
            <div className={styles.tableCell} onClick={() => handleOpenModalBtn(customerStatus)}>
              {isNumber(notSignedInFor6Months) ? (
                <div
                  className={cn(
                    styles.badge,
                    customerStatusStyles[getNotSignedInFor6MonthsStatus(notSignedInFor6Months)]
                  )}
                >
                  {notSignedInFor6Months}%
                </div>
              ) : (
                <span>&nbsp;</span>
              )}
            </div>
          ) : null,
      },
      {
        key: "usersWithMFA",
        dataIndex: "usersWithMFA",
        title: t("usersWithMFA"),
        width: "120px",
        render: (usersWithMFA: CustomerStatusModel["usersWithMFA"], customerStatus: CustomerStatusModel) =>
          usersWithMFA !== "_" ? (
            <div className={styles.tableCell} onClick={() => handleOpenModalBtn(customerStatus)}>
              {isNumber(usersWithMFA) ? (
                <div className={cn(styles.badge, customerStatusStyles[getUsersWithMFAStatus(usersWithMFA)])}>
                  {usersWithMFA}%
                </div>
              ) : (
                <span>&nbsp;</span>
              )}
            </div>
          ) : null,
      },
      {
        key: "adminsWithMFA",
        dataIndex: "adminsWithMFA",
        title: t("adminsWithMFA"),
        width: "120px",
        render: (adminsWithMFA: CustomerStatusModel["adminsWithMFA"], customerStatus: CustomerStatusModel) =>
          adminsWithMFA !== "_" ? (
            <div className={styles.tableCell} onClick={() => handleOpenModalBtn(customerStatus)}>
              {isNumber(adminsWithMFA) ? (
                <div className={cn(styles.badge, customerStatusStyles[getAdminsWithMFAStatus(adminsWithMFA)])}>
                  {adminsWithMFA}%
                </div>
              ) : (
                <span>&nbsp;</span>
              )}
            </div>
          ) : null,
      },
      {
        key: "globalAdmins",
        dataIndex: "globalAdmins",
        title: t("globalAdmins"),
        width: "120px",
        render: (globalAdmins: CustomerStatusModel["globalAdmins"], customerStatus: CustomerStatusModel) =>
          globalAdmins !== "_" ? (
            <div className={styles.tableCell} onClick={() => handleOpenModalBtn(customerStatus)}>
              {isNumber(globalAdmins) ? (
                <div className={cn(styles.badge, customerStatusStyles[getGlobalAdminsStatus(globalAdmins)])}>
                  {globalAdmins}
                </div>
              ) : (
                <span>&nbsp;</span>
              )}
            </div>
          ) : null,
      },
      {
        key: "bitlockerActivated",
        dataIndex: "bitlockerActivated",
        title: t("bitlockerActivated"),
        width: "120px",
        render: (bitlockerActivated: CustomerStatusModel["bitlockerActivated"], customerStatus: CustomerStatusModel) =>
          bitlockerActivated !== "_" ? (
            <div className={styles.tableCell} onClick={() => handleOpenModalBtn(customerStatus)}>
              {isNumber(bitlockerActivated) ? (
                <div
                  className={cn(styles.badge, customerStatusStyles[getBitlockerActivatedStatus(bitlockerActivated)])}
                >
                  {bitlockerActivated}%
                </div>
              ) : (
                <span>&nbsp;</span>
              )}
            </div>
          ) : null,
      },
      {
        key: "windows11",
        dataIndex: "windows11",
        title: t("windows11"),
        width: "120px",
        render: (windows11: CustomerStatusModel["windows11"], customerStatus: CustomerStatusModel) =>
          windows11 !== "_" ? (
            <div className={styles.tableCell} onClick={() => handleOpenModalBtn(customerStatus)}>
              {isNumber(windows11) ? (
                <div className={cn(styles.badge, customerStatusStyles[getWindows11Status(windows11)])}>
                  {windows11}%
                </div>
              ) : (
                <span>&nbsp;</span>
              )}
            </div>
          ) : null,
      },
      {
        key: "tPMOlderThan2.0",
        dataIndex: "tPMOlderThan2.0",
        title: t("tPMOlderThan2.0"),
        width: "120px",
        render: (tPMOlderThan20: CustomerStatusModel["tPMOlderThan2.0"], customerStatus: CustomerStatusModel) =>
          tPMOlderThan20 !== "_" ? (
            <div className={styles.tableCell} onClick={() => handleOpenModalBtn(customerStatus)}>
              {isNumber(tPMOlderThan20) ? (
                <div className={cn(styles.badge, customerStatusStyles[getTPMOlderThan20Status(tPMOlderThan20)])}>
                  {tPMOlderThan20}%
                </div>
              ) : (
                <span>&nbsp;</span>
              )}
            </div>
          ) : null,
      },
      {
        key: "signedInRiskUsers",
        dataIndex: "signedInRiskUsers",
        title: t("signedInRiskUsers"),
        width: "120px",
        render: (signedInRiskUsers: CustomerStatusModel["signedInRiskUsers"], customerStatus: CustomerStatusModel) =>
          signedInRiskUsers !== "_" ? (
            <div className={styles.tableCell} onClick={() => handleOpenModalBtn(customerStatus)}>
              {isNumber(signedInRiskUsers) ? (
                <div className={cn(styles.badge, customerStatusStyles[getSignedInRiskUsersStatus(signedInRiskUsers)])}>
                  {signedInRiskUsers}
                </div>
              ) : (
                <span>&nbsp;</span>
              )}
            </div>
          ) : null,
      },
      {
        key: "managedUsers",
        dataIndex: "managedUsers",
        title: t("managedUsers"),
        width: "120px",
        render: (managedUsers: CustomerStatusModel["managedUsers"], customerStatus: CustomerStatusModel) =>
          managedUsers !== "_" ? (
            <div className={styles.tableCell} onClick={() => handleOpenModalBtn(customerStatus)}>
              {isNumber(managedUsers) ? managedUsers : <span>&nbsp;</span>}
            </div>
          ) : null,
      },
    ],
    [filters, handleFilterChange, handleSelectFilterChange, handleOpenModalBtn, agreementsOptions, t]
  );

  return {
    columnsConfig,
    isCustomerStatusModalOpen,
    selectedCustomerStatusItem,
    handleOpenModalBtn,
    handleCloseModalBtn,
  };
};

export default useCustomerStatusTableConfig;
