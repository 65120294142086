import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Spinner, Table } from "@skyportal/ui-kit";
import { RequestStatus } from "types/common.types";
import { CustomerStatusModel } from "types/customerStatusApi.types";
import {
  setCustomerStatusListCurrentPageAC,
  setCustomerStatusListPerPageAC,
  setCustomerStatusListSortFieldAC,
} from "store/customerStatus/customerStatus.actions";
import useCustomerStatusTableConfig from "hooks/useCustomerStatusTableConfig";
import PaginationBox from "containers/PaginationBox";
import CardFallback from "containers/CardFallback/CardFallback";
import { useThunkDispatch } from "store";
import { customerStatusSelectors, customerStatusThunks } from "store/customerStatus";
import { areFiltersEmpty } from "utils/helpers";
import SelectedCustomerStatusModal from "./SelectedCustomerStatusModal";

import styles from "./styles.module.css";

export const dummyCustomerStatus: CustomerStatusModel = {
  customerName: "_",
  customerId: "_",
  agreementIds: "_",
  agreements: "_",
  tenantIds: "_",
  secureScore: "_",
  notSignedInFor6Months: "_",
  usersWithMFA: "_",
  adminsWithMFA: "_",
  globalAdmins: "_",
  bitlockerActivated: "_",
  windows11: "_",
  "tPMOlderThan2.0": "_",
  signedInRiskUsers: "_",
  managedUsers: "_",
};

const CustomerStatusTable = () => {
  const dispatch = useDispatch();

  const { filters, sortField, sortOrder, perPage, currentPage, requestStatus } = useSelector(
    customerStatusSelectors.getSlice
  );
  const { list, pageCount } = useSelector(customerStatusSelectors.getCustomerStatusTable);
  const { columnsConfig, isCustomerStatusModalOpen, selectedCustomerStatusItem, handleCloseModalBtn } =
    useCustomerStatusTableConfig();

  const handleSortFieldChange = useCallback((field) => dispatch(setCustomerStatusListSortFieldAC(field)), [dispatch]);
  const handlePerPageChange = useCallback((value) => dispatch(setCustomerStatusListPerPageAC(value)), [dispatch]);
  const handlePageChange = useCallback((value) => dispatch(setCustomerStatusListCurrentPageAC(value)), [dispatch]);

  const thunkDispatch = useThunkDispatch();
  const reloadData = useCallback(() => {
    thunkDispatch(customerStatusThunks.getCustomerStatusList());
  }, [thunkDispatch]);

  return (
    <Card className={styles.listCard} data-testid="customerStatusTable">
      {(requestStatus === RequestStatus.PENDING || requestStatus === RequestStatus.UNCALLED) && <Spinner show />}
      {requestStatus === RequestStatus.SUCCESS && (
        <>
          <div className={styles.tableBlock}>
            <Table
              dataSource={!list.length && areFiltersEmpty(filters) ? [] : [dummyCustomerStatus, ...list]}
              columns={columnsConfig}
              sortField={sortField}
              sortOrder={sortOrder}
              onSortFieldChange={handleSortFieldChange}
            />
          </div>
          <PaginationBox
            perPage={perPage}
            currentPage={currentPage}
            pageCount={pageCount}
            onPerPageChange={handlePerPageChange}
            onChangePage={handlePageChange}
            className={styles.paginationBox}
          />
          <SelectedCustomerStatusModal
            isOpen={isCustomerStatusModalOpen}
            selectedCustomer={selectedCustomerStatusItem}
            handleCloseModalBtn={handleCloseModalBtn}
          />
        </>
      )}
      {requestStatus === RequestStatus.FAILURE && <CardFallback onReload={reloadData} />}
    </Card>
  );
};

export default CustomerStatusTable;
